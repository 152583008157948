const PrivacyPolicy = () => {
  return (
    <div
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 01-01-2025 (DD-MM-YYYY)
      </p>

      <h2>1. Introduction</h2>
      <p>
        This Privacy Policy outlines how <strong>SCPL TEAM</strong> ("the App")
        collects, uses, and protects your personal data. By using the App, you
        agree to the collection and use of information in accordance with this
        policy.
      </p>

      <h2>2. Data Collection</h2>
      <p>
        The App requires a username and password provided by your organization.
        All user data is managed by the organization through their admin panel,
        and no direct registration is required by the user.
      </p>

      <h2>3. Location & Calendar Services</h2>
      <p>
        <strong>Location</strong>: The App uses location services to mark
        teacher attendance.
        <br />
        <strong>Calendar</strong>: The App syncs lectures and tests with the
        teacher’s calendar.
      </p>

      <h2>4. No Third-Party Services</h2>
      <p>
        Apart from the calendar and location services mentioned above, the App
        does not integrate with any third-party services.
      </p>

      <h2>5. Data Security</h2>
      <p>
        Your data is securely stored on our servers. We implement strong
        security measures to protect your data from unauthorized access,
        alteration, or disclosure.
      </p>

      <h2>6. Age Restriction</h2>
      <p>
        The App is intended for users aged 18 and above, specifically for
        teachers in educational organizations.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions regarding this Privacy Policy or data
        security, you can contact us via email at
        nextgenideasolutions1@gmail.com or visit our website{" "}
        <a href="https://scpledu.com">https://scpledu.com.</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
