import { Formik } from "formik";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { FetchData, useFetch } from "use-http";
import * as Yup from "yup";
import AppProvider from "../components/AppProvider";
import CustomPageLayout from "../components/CustomPageLayout";
import {
  BackupSessionData,
  BackupSessionRoot,
  GetAllDesignationAPIRoot,
  GetAllStudentRoot,
  GetByIdStudentRoot,
} from "../utils/types";

interface FormValues {
  subject: string;
  branch: string;
  batch: string;
  //   teacher: string;
  standard: string;
  fromTime: string;
  toTime: string;
  date: string;
  isActive: boolean;
}

const BackupSession = () => {
  return (
    <CustomPageLayout Add={Add} Index={Index} Update={Update} View={View} />
  );
};

const Index = () => {
  const navigate = useNavigate();
  const {
    get,
    delete: deleteDesignation,
    response,
    loading,
  } = useFetch<BackupSessionRoot>("/backupSession");

  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);

      await get()
        .then((res) => {
          if (res) {
            if (res.success) {
              setRefreshing(false);
            }
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [get]);

  const getData = async () => {
    setRefreshing(true);

    await get()
      .then((res) => {
        if (res) {
          if (res.success) {
            setRefreshing(false);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleDelete = async (_id: string) => {
    setRefreshing(true);

    await deleteDesignation(`${_id}`).then((res) => {
      if (res.success) {
        Swal.fire({
          title: "Deleted!",
          text: "Your data has been deleted.",
          icon: "success",
        });
      }
    });
    await getData();

    setRefreshing(false);
  };

  if (loading || refreshing) {
    return (
      <div>
        <AppProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner />
          </div>
        </AppProvider>
      </div>
    );
  }

  if (!response.ok) {
    return <div>.hi..</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Back-Up Session</h5>

                  {true && (
                    <Button
                      onClick={() => navigate("/backupSession?action=add")}
                    >
                      ADD
                    </Button>
                  )}
                </div>
              </Card.Header>

              <Card.Body>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Standard</th>
                      <th>Subject</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Teacher</th>
                      <th>Branch</th>
                      <th>Batch</th>

                      <th>Status</th>
                      {/* <th
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Action
                      </th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {response &&
                      response.ok &&
                      response.data &&
                      response?.data?.data.map((item: any, index: any) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>
                              {moment(item.date).format("DD-MM-YYYY") || ""}
                            </td>
                            <td>{item.standard || ""}</td>
                            <td>{item.subject || ""}</td>
                            <td>{item.fromTime || ""}</td>
                            <td>{item.toTime || ""}</td>
                            <td>{item.teacher || ""}</td>
                            <td>{item.branch || ""}</td>
                            <td>{item.batch || ""}</td>

                            <td>
                              {item.isActionPerformed ? "Approved" : "Rejected"}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

// const Add = () => {
//   const navigate = useNavigate();
//   const { post } = useFetch<BackupSessionRoot>("/backupSession");
//   const {
//     get: getSubject,
//     response: subjectResponse,
//     loading: subjectLoading,
//     error: subjectError,
//   } = useFetch<GetAllDesignationAPIRoot>("/subject");
//   const {
//     get: getBranch,
//     response: branchResponse,
//     loading: branchLoading,
//     error: branchError,
//   } = useFetch<GetAllDesignationAPIRoot>("/branch");
//   const {
//     get: getBatch,
//     response: batchResponse,
//     loading: batchLoading,
//     error: batchError,
//   } = useFetch<GetAllDesignationAPIRoot>("/batch");
//   // const {
//   //   get: getTeacher,
//   //   response: teacherResponse,
//   //   loading: teacherLoading,
//   //   error: teacherError,
//   // } = useFetch<GetAllDesignationAPIRoot>("/teacher");
//   const {
//     get: getStandard,
//     response: standardResponse,
//     loading: standardLoading,
//     error: standardError,
//   } = useFetch<GetAllDesignationAPIRoot>("/standard");

//   const [refreshing, setRefreshing] = useState(false);

//   const [isSelectStudent, setIsSelectStudent] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       setRefreshing(true);

//       try {
//         await getBatch();

//         await getBranch();

//         await getStandard();

//         await getSubject();
//       } catch (err) {
//         console.log(err);
//       }

//       setRefreshing(false);
//     };

//     fetchData();
//   }, []);

//   const handleSubmit = async (values: FormValues) => {
//     try {
//       const res = await post(values);
//       if (res.success) {
//         alert("Back-up Session added successfully!");
//         navigate(-1);
//       } else {
//         alert(res.message);
//       }
//     } catch (err) {
//       alert("Error adding session");
//       console.log(err);
//     }
//   };

//   if (refreshing) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <Spinner />
//       </div>
//     );
//   }

//   return (
//     <div>
//       <AppProvider>
//         <div>
//           <Container
//             style={{
//               marginTop: "1rem",
//             }}
//           >
//             <Card>
//               <Card.Header>
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "flex-end",
//                     gap: "1rem",
//                   }}
//                 >
//                   <div onClick={() => navigate(-1)}>
//                     <i
//                       className="bi bi-arrow-left"
//                       style={{
//                         fontSize: "23px",
//                       }}
//                     ></i>
//                   </div>
//                   <div>
//                     <h6>Add Back-Up Session</h6>
//                   </div>
//                 </div>
//               </Card.Header>

//               <Card.Body>
//                 <Formik
//                   validationSchema={Yup.object().shape({
//                     subject: Yup.string().required(),
//                     branch: Yup.string().required(),
//                     batch: Yup.string().required(),

//                     standard: Yup.string().required(),
//                     fromTime: Yup.string().required(),
//                     toTime: Yup.string().required(),
//                     date: Yup.string().required(),
//                     // student: Yup.string().required(),
//                     isActive: Yup.boolean().oneOf([true, false]),
//                   })}
//                   initialValues={{
//                     subject: "",
//                     branch: "",
//                     batch: "",

//                     standard: "",
//                     fromTime: "",
//                     toTime: "",
//                     date: "",

//                     isActive: true,
//                   }}
//                   onSubmit={(values, { setSubmitting, setErrors }) => {
//                     handleSubmit(values)
//                       .catch((error) => {
//                         alert("Validation errors: " + error);
//                         setErrors(error);
//                       })
//                       .finally(() => setSubmitting(false));
//                   }}
//                 >
//                   {({
//                     handleSubmit,
//                     handleChange,
//                     values,
//                     touched,
//                     errors,
//                   }) => (
//                     <Form onChange={handleChange} onSubmit={handleSubmit}>
//                       <Row
//                         className="mb-3"
//                         style={{
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                         }}
//                       >
//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik01"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             Standard <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>

//                           <Form.Select
//                             aria-label="Default select example"
//                             name="standard"
//                             value={values.standard}
//                             isInvalid={!!touched.standard && !!errors.standard}
//                           >
//                             <option value={""}>select</option>

//                             {standardResponse &&
//                               standardResponse.data &&
//                               standardResponse.data?.data &&
//                               standardResponse.data?.data.map((item) => (
//                                 <option value={item._id}>{item.name}</option>
//                               ))}
//                           </Form.Select>

//                           <Form.Control.Feedback type="invalid">
//                             {errors.standard}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik02"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             Batch <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>

//                           <Form.Select
//                             aria-label="Default select example"
//                             name="batch"
//                             value={values.batch}
//                             isInvalid={!!touched.batch && !!errors.batch}
//                           >
//                             <option value={""}>select</option>

//                             {batchResponse &&
//                               batchResponse.data &&
//                               batchResponse.data?.data &&
//                               batchResponse.data?.data.map((item) => (
//                                 <option value={item._id}>{item.name}</option>
//                               ))}
//                           </Form.Select>

//                           <Form.Control.Feedback type="invalid">
//                             {errors.batch}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik03"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             Branch <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>

//                           <Form.Select
//                             aria-label="Default select example"
//                             name="branch"
//                             value={values.branch}
//                             isInvalid={!!touched.branch && !!errors.branch}
//                           >
//                             <option value={""}>select</option>

//                             {branchResponse &&
//                               branchResponse.data &&
//                               branchResponse.data?.data &&
//                               branchResponse.data?.data.map((item) => (
//                                 <option value={item._id}>{item.name}</option>
//                               ))}
//                           </Form.Select>

//                           <Form.Control.Feedback type="invalid">
//                             {errors.branch}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik04"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             Subject <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>

//                           <Form.Select
//                             aria-label="Default select example"
//                             name="subject"
//                             onChange={handleChange}
//                             value={values.subject}
//                             isInvalid={!!touched.subject && !!errors.subject}
//                           >
//                             <option value={""}>select</option>

//                             {subjectResponse &&
//                               subjectResponse.data &&
//                               subjectResponse.data?.data &&
//                               subjectResponse.data?.data.map((item) => (
//                                 <option value={item._id}>{item.name}</option>
//                               ))}
//                           </Form.Select>

//                           <Form.Control.Feedback type="invalid">
//                             {errors.subject}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik06"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             From time <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>
//                           <Form.Control
//                             type="time"
//                             name="fromTime"
//                             value={values.fromTime}
//                             onChange={handleChange}
//                             isValid={touched.fromTime && !errors.fromTime}
//                             isInvalid={!!touched.fromTime && !!errors.fromTime}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.fromTime}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik07"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             To time <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>
//                           <Form.Control
//                             type="time"
//                             name="toTime"
//                             value={values.toTime}
//                             onChange={handleChange}
//                             isValid={touched.toTime && !errors.toTime}
//                             isInvalid={!!touched.toTime && !!errors.toTime}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.toTime}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group
//                           as={Col}
//                           md="4"
//                           controlId="validationFormik08"
//                           className="mb-3"
//                         >
//                           <Form.Label>
//                             Date <span style={{ color: "red" }}>*</span>{" "}
//                           </Form.Label>
//                           <Form.Control
//                             type="date"
//                             name="date"
//                             value={values.date}
//                             onChange={handleChange}
//                             isValid={touched.date && !errors.date}
//                             isInvalid={!!touched.date && !!errors.date}
//                           />
//                           <Form.Control.Feedback type="invalid">
//                             {errors.date}
//                           </Form.Control.Feedback>
//                         </Form.Group>

//                         <Form.Group as={Col} md="4" className="mb-3">
//                           <Form.Check
//                             required
//                             name="isActive"
//                             label="Is Active"
//                             onChange={handleChange}
//                             defaultChecked={values.isActive}
//                           />
//                         </Form.Group>

//                         <Col
//                           style={{
//                             display: "flex",
//                             justifyContent: "flex-end",
//                           }}
//                           className="mb-3"
//                         >
//                           <Button
//                             type="submit"
//                             onSubmit={() => {
//                               setIsSelectStudent(true);
//                             }}
//                           >
//                             Select Students
//                           </Button>
//                         </Col>
//                       </Row>
//                     </Form>
//                   )}
//                 </Formik>
//               </Card.Body>
//             </Card>
//           </Container>
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

const Add = () => {
  const navigate = useNavigate();
  const [refreshing, setRefreshing] = useState(false);
  const [isSelectedStudents, setIsSelectedStudents] = useState(false);
  const [attendanceList, setAttendanceList] = useState<
    { student: string; isPresent: boolean }[]
  >([]);
  // const { post } = useFetch<{
  //   success: boolean;
  //   data: string;
  //   message: string;
  // }>("/backupSession");
  const { post } = useFetch<BackupSessionRoot>("/backupSession");
  const { get: getStandard, response: standardResponse } =
    useFetch<GetAllDesignationAPIRoot>("/standard");
  const { get: getSubject, response: subjectResponse } =
    useFetch<GetAllDesignationAPIRoot>("/subject");
  const { get: getBranch, response: branchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/branch");
  const { get: getBatch, response: batchResponse } =
    useFetch<GetAllDesignationAPIRoot>("/batch");
  const { post: getStudents, response } =
    useFetch<GetAllStudentRoot>("/student");

  useEffect(() => {
    console.log(attendanceList);
  }, [attendanceList]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setRefreshing(true);

      await getStandard();

      await getSubject();

      await getBranch();

      await getBatch();

      setRefreshing(false);
    } catch (err) {
      setRefreshing(false);
    } finally {
      setRefreshing(false);
    }
  };

  // const fetchStudents = async (values) => {
  //   try {
  //     const res = await getStudents({
  //       standard: values.standard,
  //       batch: values.batch,
  //       branch: values.branch,
  //     });

  //     if (res && res.data && res.data.data.length > 0) {
  //       setAttendanceList(
  //         res.data.data.map((item) => ({
  //           student: item._id,
  //           isPresent: false,
  //         }))
  //       );
  //       setIsSelectedStudents(true);
  //     } else {
  //       setAttendanceList([]);
  //       setIsSelectedStudents(false);
  //       alert("No students found");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching students", error);
  //   }
  // };

  const handleSubmit = async (values: {
    subject: string;
    branch: string;
    batch: string;
    standard: string;
    fromTime: string | Date;
    toTime: string | Date;
    date: string | Date;
    isActive: boolean;
  }) => {
    if (attendanceList.length === 0) {
      alert("Please select students for backup session");

      return;
    }

    await post({
      ...values,
      date: moment(values.date).format("YYYY-MM-DD"),
      attendanceList: attendanceList,
      isActionPerformed: false,
      isApproved: false,
    })
      // await post(values)
      .then(async (res) => {
        console.log(res);
        if (res.success) {
          alert(res.message);

          setIsSelectedStudents(false);
          setAttendanceList([]);
        } else {
          alert(res.message);
        }
      })
      .catch((err) => alert(err))
      .finally(() => setRefreshing(false));
  };
  // const handleSubmit = async (values: FormValues) => {
  //   try {
  //     const res = await post(values);
  //     if (res.success) {
  //       alert("BackUp Session added successfully!");
  //       navigate(-1);
  //     } else {
  //       alert(res.message);
  //     }
  //   } catch (err) {
  //     alert("Error adding lecture");
  //     console.log(err);
  //   }
  // };

  // const handleSubmit = async (values: any) => {
  //   try {
  //     const payload = {
  //       ...values,
  //       date: moment(values.date).format("YYYY-MM-DD"),
  //       attendanceList: attendanceList,
  //       isActionPerformed: false,
  //       isApproved: false,
  //     };

  //     const res = await post(payload);
  //     if (res.success) {
  //       alert("Back-Up Session added successfully!");
  //       navigate(-1); // Navigate back to previous page or desired route
  //     } else {
  //       alert(res.message || "Failed to add Back-Up Session");
  //     }
  //   } catch (err) {
  //     console.error("Error adding Back-Up Session:", err);
  //     alert("Error adding Back-Up Session. Please try again.");
  //   }
  // };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Add Back-Up Session</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    subject: Yup.string().required(),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),

                    standard: Yup.string().required(),
                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    // student: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  initialValues={{
                    subject: "",
                    branch: "",
                    batch: "",

                    standard: "",
                    // fromTime: new Date(),
                    // toTime: new Date(),
                    // date: new Date(),
                    fromTime: "",
                    toTime: "",
                    date: "",
                    student: [],
                    isActive: true,
                  }}
                  // onSubmit={(values, { setSubmitting, setErrors }) => {
                  //   handleSubmit(values)
                  //     .catch((error) => {
                  //       alert("Validation errors: " + error);
                  //       setErrors(error);
                  //     })
                  //     .finally(() => setSubmitting(false));
                  // }}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => {
                    return (
                      <Form onChange={handleChange} onSubmit={handleSubmit}>
                        <GetStudents
                          values={values}
                          getData={getStudents}
                          setAttendanceList={setAttendanceList}
                        />
                        <Row
                          className="mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik01"
                            className="mb-3"
                          >
                            <Form.Label>
                              Standard <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              name="standard"
                              // onChange={(e) => {
                              //   setData({ ...data, standard: e.target.value });
                              //   handleChange(e);
                              // }}
                              value={values.standard}
                              isInvalid={
                                !!touched.standard && !!errors.standard
                              }
                            >
                              <option value={""}>select</option>

                              {standardResponse &&
                                standardResponse.data &&
                                standardResponse.data?.data &&
                                standardResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                              {errors.standard}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik02"
                            className="mb-3"
                          >
                            <Form.Label>
                              Batch <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              name="batch"
                              // onChange={(e) => {
                              //   setData({ ...data, batch: e.target.value });
                              //   handleChange(e);
                              // }}
                              value={values.batch}
                              isInvalid={!!touched.batch && !!errors.batch}
                            >
                              <option value={""}>select</option>

                              {batchResponse &&
                                batchResponse.data &&
                                batchResponse.data?.data &&
                                batchResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                              {errors.batch}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik03"
                            className="mb-3"
                          >
                            <Form.Label>
                              Branch <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              name="branch"
                              // onChange={(e) => {
                              //   setData({ ...data, branch: e.target.value });
                              //   handleChange(e);
                              // }}
                              value={values.branch}
                              isInvalid={!!touched.branch && !!errors.branch}
                            >
                              <option value={""}>select</option>

                              {branchResponse &&
                                branchResponse.data &&
                                branchResponse.data?.data &&
                                branchResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                              {errors.branch}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik04"
                            className="mb-3"
                          >
                            <Form.Label>
                              Subject <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>

                            <Form.Select
                              aria-label="Default select example"
                              name="subject"
                              onChange={handleChange}
                              value={values.subject}
                              isInvalid={!!touched.subject && !!errors.subject}
                            >
                              <option value={""}>select</option>

                              {subjectResponse &&
                                subjectResponse.data &&
                                subjectResponse.data?.data &&
                                subjectResponse.data?.data.map((item) => (
                                  <option value={item._id}>{item.name}</option>
                                ))}
                            </Form.Select>

                            <Form.Control.Feedback type="invalid">
                              {errors.subject}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik06"
                            className="mb-3"
                          >
                            <Form.Label>
                              From time <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              type="time"
                              name="fromTime"
                              value={values.fromTime}
                              onChange={handleChange}
                              isValid={touched.fromTime && !errors.fromTime}
                              isInvalid={
                                !!touched.fromTime && !!errors.fromTime
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.fromTime}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik07"
                            className="mb-3"
                          >
                            <Form.Label>
                              To time <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              type="time"
                              name="toTime"
                              value={values.toTime}
                              onChange={handleChange}
                              isValid={touched.toTime && !errors.toTime}
                              isInvalid={!!touched.toTime && !!errors.toTime}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.toTime}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="4"
                            controlId="validationFormik08"
                            className="mb-3"
                          >
                            <Form.Label>
                              Date <span style={{ color: "red" }}>*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              type="date"
                              name="date"
                              value={values.date}
                              onChange={handleChange}
                              isValid={touched.date && !errors.date}
                              isInvalid={!!touched.date && !!errors.date}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.date}
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} md="4" className="mb-3">
                            <Form.Check
                              required
                              name="isActive"
                              label="Is Active"
                              onChange={handleChange}
                              defaultChecked={values.isActive}
                            />
                          </Form.Group>

                          <Col
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className="mb-3"
                          >
                            <Button
                              type="submit"
                              // onClick={setIsSelectedStudents(true)}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>

                        {/* {isSelectedStudents && ( */}
                        <Row>
                          <Col>
                            <h5>Select Students From Below</h5>

                            {response.data?.data &&
                            response.data?.data.length > 0 ? (
                              response.data?.data.map((item, index) => (
                                <CustomCheckbox
                                  _id={item._id}
                                  name={item.name}
                                  attendanceList={attendanceList}
                                  setAttendanceList={setAttendanceList}
                                  key={item._id}
                                />
                              ))
                            ) : (
                              <p>No students available</p>
                            )}
                          </Col>
                        </Row>
                        {/* )} */}
                      </Form>
                    );
                  }}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const CustomCheckbox: React.FC<{
  _id: string;
  name: string;
  setAttendanceList: (
    values: { student: string; isPresent: boolean }[]
  ) => void;
  attendanceList: { student: string; isPresent: boolean }[];
}> = ({ _id, name, setAttendanceList, attendanceList }) => {
  const [checked, setChecked] = React.useState(
    attendanceList.find((x) => x.student === _id) ? true : false
  );

  useEffect(() => {
    if (attendanceList.find((x) => x.student === _id)) setChecked(true);
    else setChecked(false);
  }, [attendanceList]);

  return (
    <Form.Check
      name={name}
      key={_id}
      type="checkbox"
      label={name}
      checked={checked ? true : false}
      value={_id}
      onChange={(e) => {
        console.log(e.target.value);

        if (checked) {
          setAttendanceList(attendanceList.filter((x) => x.student !== _id));
        } else {
          setAttendanceList([
            ...attendanceList,
            { isPresent: false, student: _id },
          ]);
        }
      }}
    />
  );
};

const GetStudents: React.FC<{
  values: {
    subject: string;
    branch: string;
    batch: string;
    standard: string;
    fromTime: string | Date;
    toTime: string | Date;
    date: string | Date;
    isActive: boolean;
  };
  getData: FetchData<GetAllStudentRoot>;
  setAttendanceList: (
    values: { student: string; isPresent: boolean }[]
  ) => void;
}> = ({ getData, setAttendanceList, values }) => {
  useEffect(() => {
    (async () => {
      if (values.standard && values.batch && values.branch)
        await getData("/filter", {
          standard: values.standard,
          batch: values.batch,
          branch: values.branch,
        });

      setAttendanceList([]);
    })();
  }, [values.standard, values.batch, values.branch]);

  return <></>;
};

const Update = () => {
  const navigate = useNavigate();
  const { get, post } = useFetch("/lecture");
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const [initValues, setInitValues] = useState({
    branch: "",
    batch: "",
    standard: "",
    subject: "",
    teacher: "",
    fromTime: "",
    toTime: "",
    date: "",
    isActive: false,
  });
  const [key, setKey] = useState(Math.random());
  const {
    get: getBranch,
    response: branchResponse,
    loading: branchLoading,
    error: branchError,
  } = useFetch<GetAllDesignationAPIRoot>("/branch");
  const {
    get: getStandard,
    response: standardResponse,
    loading: standardLoading,
    error: standardError,
  } = useFetch<GetAllDesignationAPIRoot>("/standard");
  const {
    get: getBatch,
    response: batchResponse,
    loading: batchLoading,
    error: batchError,
  } = useFetch<GetAllDesignationAPIRoot>("/batch");
  const {
    get: getSubject,
    response: subjectResponse,
    loading: subjectLoading,
    error: subjectError,
  } = useFetch<GetAllDesignationAPIRoot>("/subject");
  const {
    get: getTeacher,
    response: teacherResponse,
    loading: teacherLoading,
    error: teacherError,
  } = useFetch<GetAllDesignationAPIRoot>("/teacher");

  useEffect(() => {
    (async () => {
      await getStandard();
      await getBranch();
      await getBatch();
      await getSubject();
      await getTeacher();

      await get(`${parsed.id}`)
        .then((res) => {
          if (res.success) {
            console.log(res);

            setInitValues({
              isActive: res.data?.isActive,
              branch: res.data?.branch?._id || " ",
              batch: res.data?.batch?._id || "",
              standard: res.data?.standard?._id || "",
              subject: res.data?.subject?._id || "",
              teacher: res.data?.teacher?._id || "",
              fromTime: res.data?.fromTime || "",
              toTime: res.data?.toTime || "",
              date: res.data?.date || "",
            });

            setKey(Math.random());
          }
        })
        .catch((err) => console.log(err));
    })();
  }, [parsed.id, get]);

  const handleSubmit = async (values: {
    branch: string;
    batch: string;
    standard: string;
    subject: string;
    teacher: string;
    fromTime: string;
    toTime: string;
    date: string;
    isActive: boolean;
  }) => {
    await post(`${parsed.id}`, values)
      .then(async (res) => {
        if (res.success) {
          await get()
            .then((res) => res.success && navigate(-1))
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>Update Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <Formik
                  validationSchema={Yup.object().shape({
                    standard: Yup.string().required(),
                    branch: Yup.string().required(),
                    batch: Yup.string().required(),
                    subject: Yup.string().required(),
                    teacher: Yup.string().required(),
                    fromTime: Yup.string().required(),
                    toTime: Yup.string().required(),
                    date: Yup.string().required(),
                    isActive: Yup.boolean().oneOf([true, false]),
                  })}
                  onSubmit={handleSubmit}
                  initialValues={initValues}
                  enableReinitialize
                  key={key}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form onChange={handleChange} onSubmit={handleSubmit}>
                      <Row
                        className="mb-3"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Branch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="branch"
                            onChange={handleChange}
                            value={values.branch}
                            isInvalid={!!touched.branch && !!errors.branch}
                          >
                            <option value={""}>select</option>

                            {branchResponse &&
                              branchResponse.data &&
                              branchResponse.data?.data &&
                              branchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.branch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Standard <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="standard"
                            onChange={handleChange}
                            value={values.standard}
                            isInvalid={!!touched.standard && !!errors.standard}
                          >
                            <option value={""}>select</option>

                            {standardResponse &&
                              standardResponse.data &&
                              standardResponse.data?.data &&
                              standardResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.standard}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Batch <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="batch"
                            onChange={handleChange}
                            value={values.batch}
                            isInvalid={!!touched.batch && !!errors.batch}
                          >
                            <option value={""}>select</option>

                            {batchResponse &&
                              batchResponse.data &&
                              batchResponse.data?.data &&
                              batchResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.batch}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Subject <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="subject"
                            onChange={handleChange}
                            value={values.subject}
                            isInvalid={!!touched.subject && !!errors.subject}
                          >
                            <option value={""}>select</option>

                            {subjectResponse &&
                              subjectResponse.data &&
                              subjectResponse.data?.data &&
                              subjectResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.subject}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Label>
                            Teacher <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>

                          <Form.Select
                            aria-label="Default select example"
                            name="teacher"
                            onChange={handleChange}
                            value={values.teacher}
                            isInvalid={!!touched.teacher && !!errors.teacher}
                          >
                            <option value={""}>select</option>

                            {teacherResponse &&
                              teacherResponse.data &&
                              teacherResponse.data?.data &&
                              teacherResponse.data?.data.map((item) => (
                                <option value={item._id}>{item.name}</option>
                              ))}
                          </Form.Select>

                          <Form.Control.Feedback type="invalid">
                            {errors.teacher}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            From time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="fromTime"
                            value={values.fromTime}
                            onChange={handleChange}
                            isValid={touched.fromTime && !errors.fromTime}
                            isInvalid={!!touched.fromTime && !!errors.fromTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.fromTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik06"
                          className="mb-3"
                        >
                          <Form.Label>
                            To time <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="time"
                            name="toTime"
                            value={values.toTime}
                            onChange={handleChange}
                            isValid={touched.toTime && !errors.toTime}
                            isInvalid={!!touched.toTime && !!errors.toTime}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.toTime}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationFormik08"
                          className="mb-3"
                        >
                          <Form.Label>
                            Date <span style={{ color: "red" }}>*</span>{" "}
                          </Form.Label>
                          <Form.Control
                            type="date"
                            name="date"
                            value={values.date}
                            onChange={handleChange}
                            isValid={touched.date && !errors.date}
                            isInvalid={!!touched.date && !!errors.date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.date}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" className="mb-3">
                          <Form.Check
                            required
                            name="isActive"
                            label="Is Active"
                            onChange={handleChange}
                            defaultChecked={values.isActive}
                          />
                        </Form.Group>

                        <Col
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="mb-3"
                        >
                          <Button type="submit">SUBMIT</Button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

const View = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const parsed = queryString.parse(search);
  const { get, response, loading, error } =
    useFetch<GetByIdStudentRoot>("/lecture");
  const [refreshing, setRefreshing] = useState(true);

  useEffect(() => {
    (async () => {
      setRefreshing(true);
      if (parsed.id) {
        await get(`${parsed.id}`)
          .then((res) => {
            if (res) {
              if (res.success) {
                setRefreshing(false);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            setRefreshing(false);
          });
      }
    })();
  }, [parsed.id, get]);

  if (loading || refreshing) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (!response.ok) {
    return <div>...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      <AppProvider>
        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    gap: "1rem",
                  }}
                >
                  <div onClick={() => navigate(-1)}>
                    <i
                      className="bi bi-arrow-left"
                      style={{
                        fontSize: "23px",
                      }}
                    ></i>
                  </div>
                  <div>
                    <h6>View Lecture</h6>
                  </div>
                </div>
              </Card.Header>

              <Card.Body>
                <div>
                  {response && response.ok && response.data && (
                    <Table bordered hover>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{response.data?.data.name || ""}</td>
                        </tr>

                        <tr>
                          <td>Username</td>
                          <td>{response.data?.data.username || ""}</td>
                        </tr>

                        <tr>
                          <td>Is Active</td>
                          <td>{response.data?.data.isActive ? "Yes" : "No"}</td>
                        </tr>

                        <tr>
                          <td>Created At</td>
                          <td>
                            {moment(response.data?.data.createdAt).format(
                              "DD-MM-YYYY H:mm A"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  )}
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default BackupSession;
