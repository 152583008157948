// import React, { useEffect, useState } from "react";
// import AppProvider from "../components/AppProvider";
// import { Badge, Button, Card, Container, Table } from "react-bootstrap";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { useFetch } from "use-http";
// import { GetAllLecturesDaum } from "../utils/types";
// import "../css/class.css";
// import { GetAllMyLecturesRoot } from "../utils/types";

// const Class = () => {
//   const navigate = useNavigate();
//   // for horizontal calander above tables...............................
//   const today = new Date();
//   const todayDate = today.getDate();
//   const days = Array.from({ length: 31 }, (_, index) => {
//     const date = new Date(today.getFullYear(), today.getMonth(), index + 1);
//     return date.toLocaleString("default", { weekday: "short" });
//   });
//   const dates = Array.from({ length: 31 }, (_, i) => i + 1);

//   // for select date in input field of table........................
//   const [selectedDate, setSelectedDate] = useState<string>(
//     moment().format("YYYY-MM-DD")
//   );

//   const [tableData, setTableData] = useState<GetAllLecturesDaum[]>([]); // for table data //
//   const { get } = useFetch<GetAllMyLecturesRoot>("/lecture");
//   const [refreshing, setRefreshing] = useState(true);

//   useEffect(() => {
//     fetchTableData(selectedDate);
//   }, [selectedDate]);

//   const fetchTableData = async (date: string) => {
//     setRefreshing(true);
//     const res = await get(`?date=${date}`);
//     if (res && res.success) {
//       setTableData(res.data || []);
//     } else {
//       setTableData([]);
//     }
//     setRefreshing(false);
//   };

//   const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const selected = event.target.value;
//     setSelectedDate(selected);
//   };

//   const filteredData = tableData.filter(
//     (item) => moment(item.date).format("YYYY-MM-DD") === selectedDate
//   );

//   // const isLectureActive = (fromTime: string, toTime: string) => {
//   //   const now = moment();
//   //   const startTime = moment(fromTime, "HH:mm").subtract(10, "minutes");
//   //   const endTime = moment(toTime, "HH:mm").add(10, "minutes");
//   //   return now.isBetween(startTime, endTime);
//   // };
//   const isAttendanceAllowed = (
//     fromTime: string,
//     toTime: string,
//     date: string
//   ) => {
//     const now = moment();
//     const startTime = moment(
//       `${date} ${fromTime}`,
//       "YYYY-MM-DD HH:mm"
//     ).subtract(10, "minutes");
//     const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
//       10,
//       "minutes"
//     );
//     return now.isBetween(startTime, endTime);
//   };

//   const isLectureCompleted = (toTime: string, date: string) => {
//     const now = moment();
//     const lectureDate = moment(date, "YYYY-MM-DD");
//     const endTime = moment(toTime, "HH:mm");

//     return (
//       lectureDate.isBefore(now, "day") ||
//       (lectureDate.isSame(now, "day") && now.isAfter(endTime))
//     );
//   };
//   // const isAttendanceAllowed = (toTime: string, date: string) => {
//   //   const now = moment();
//   //   const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
//   //     10,
//   //     "minutes"
//   //   );
//   //   return now.isBefore(endTime);
//   // };
//   // const isAttendanceAllowed = (
//   //   fromTime: string,
//   //   toTime: string,
//   //   date: string
//   // ) => {
//   //   const now = moment();
//   //   const startTime = moment(
//   //     `${date} ${fromTime}`,
//   //     "YYYY-MM-DD HH:mm"
//   //   ).subtract(10, "minutes");
//   //   const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
//   //     10,
//   //     "minutes"
//   //   );
//   //   return now.isBetween(startTime, endTime);
//   // };

//   return (
//     <div>
//       <AppProvider>
//         <h5 style={{ textAlign: "center", marginTop: "10px" }}>
//           Today's Class Report
//         </h5>

//         <div className="date-picker">
//           {dates.map((date, index) => {
//             if (date === todayDate) {
//               return (
//                 <div
//                   key={index}
//                   className={`date-item ${
//                     moment(today).add(index, "days").format("YYYY-MM-DD") ===
//                     selectedDate
//                       ? "selected"
//                       : ""
//                   }`}
//                   style={{
//                     width: "50px",
//                     backgroundColor:
//                       date === todayDate ? "rgb(26 111 117)" : "transparent",
//                     color: date === todayDate ? "white" : "black",
//                     fontWeight: date === todayDate ? "bolder" : "normal",
//                   }}
//                 >
//                   {/* <div className="day">
//                     {days[(currentDayIndex + index) % 7]}
//                   </div> */}
//                   <div className="date">{date}</div>
//                 </div>
//               );
//             }
//             return null;
//           })}
//         </div>

//         <div>
//           <Container
//             style={{
//               marginTop: "1rem",
//               marginBottom: "1rem",
//             }}
//           >
//             <Card>
//               <Card.Header>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     height: "3rem",
//                     alignItems: "center",
//                   }}
//                 >
//                   <h5>Lecture ({moment(selectedDate).format("DD-MM-YYYY")})</h5>

//                   <input
//                     type="date"
//                     style={{ borderRadius: "6px", padding: "6px" }}
//                     value={selectedDate}
//                     onChange={handleDateChange}
//                   />
//                 </div>
//               </Card.Header>

//               <Card.Body>
//                 <div className="table-responsive">
//                   <Table striped bordered hover>
//                     <thead>
//                       <tr>
//                         <th>#</th>
//                         <th>Date</th>
//                         <th>Subject</th>
//                         <th>From</th>
//                         <th>To</th>
//                         <th>Teacher</th>
//                         <th>Branch</th>
//                         <th>Batch</th>
//                         <th>Standard</th>
//                         <th>Status</th>
//                         <th
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                         >
//                           Action
//                         </th>
//                       </tr>
//                     </thead>

//                     <tbody>
//                       {filteredData.length > 0 ? (
//                         filteredData
//                           .sort((a, b) => {
//                             const timeA = a.fromTime.split(":");
//                             const timeB = b.fromTime.split(":");
//                             return (
//                               parseInt(timeA[0]) * 60 +
//                               parseInt(timeA[1]) -
//                               (parseInt(timeB[0]) * 60 + parseInt(timeB[1]))
//                             );
//                           })
//                           .map((item, index) => (
//                             <tr key={item._id}>
//                               <td>{index + 1}</td>
//                               <td>
//                                 {moment(item.date).format("DD-MM-YYYY") || ""}
//                               </td>
//                               <td>{item.subject.name || ""}</td>
//                               <td>{item.fromTime || ""}</td>
//                               <td>{item.toTime || ""}</td>
//                               <td>{item.teacher.name || ""}</td>
//                               <td>{item.branch.name || ""}</td>
//                               <td>{item.batch.name || ""}</td>
//                               <td>{item.standard.name || ""}</td>

//                               <td>
//                                 {isLectureCompleted(item.toTime, item.date) ? (
//                                   <Badge bg="success">Completed</Badge>
//                                 ) : (
//                                   <Badge bg="danger">Pending</Badge>
//                                 )}
//                               </td>
//                               <td
//                                 style={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                   justifyContent: "center",
//                                 }}
//                               >
//                                 <div style={{ display: "flex", gap: "1rem" }}>
//                                   <Button
//                                     onClick={() =>
//                                       navigate(`/attendance/${item._id}`)
//                                     }
//                                     // disabled={
//                                     //   !isLectureActive(
//                                     //     item.fromTime,
//                                     //     item.toTime
//                                     //   ) ||
//                                     //   isLectureCompleted(item.toTime, item.date)
//                                     // }
//                                     // disabled={
//                                     //   !isAttendanceAllowed(
//                                     //     item.fromTime,
//                                     //     item.toTime,
//                                     //     item.date
//                                     //   )
//                                     // }
//                                   >
//                                     Attendence
//                                   </Button>
//                                 </div>
//                               </td>
//                             </tr>
//                           ))
//                       ) : (
//                         <tr>
//                           <td colSpan={11} style={{ textAlign: "center" }}>
//                             No data available for the selected date.
//                           </td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </Table>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Container>
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Class;

// import React, { useEffect, useState } from "react";
// import AppProvider from "../components/AppProvider";
// import { Badge, Button, Card, Container, Table } from "react-bootstrap";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { useFetch } from "use-http";
// import { GetAllLecturesDaum } from "../utils/types";
// import "../css/class.css";
// import { GetAllMyLecturesRoot } from "../utils/types";
// import { useAppSelector } from "../store/hooks"; // Ensure this hook is correctly implemented to access the state

// const Class = () => {
//   const navigate = useNavigate();
//   // for horizontal calander above tables...............................
//   const today = new Date();
//   const todayDate = today.getDate();
//   const days = Array.from({ length: 31 }, (_, index) => {
//     const date = new Date(today.getFullYear(), today.getMonth(), index + 1);
//     return date.toLocaleString("default", { weekday: "short" });
//   });
//   const dates = Array.from({ length: 31 }, (_, i) => i + 1);

//   // for select date in input field of table........................
//   const [selectedDate, setSelectedDate] = useState<string>(
//     moment().format("YYYY-MM-DD")
//   );

//   const [tableData, setTableData] = useState<GetAllLecturesDaum[]>([]); // for table data //
//   const { get } = useFetch<GetAllMyLecturesRoot>("/lecture");
//   const [refreshing, setRefreshing] = useState(true);
//   // Get teacherId from the auth state
//   const teacherId = useAppSelector((state) => state.authReducer._id);
//   // Get branchId from the branch state
//   const branchId = useAppSelector((state) => state.branchReducer._id);

//   // const teacherId = useAppSelector((state) => state.authReducer.teacher?._id);

//   useEffect(() => {
//     fetchTableData(selectedDate);
//   }, [selectedDate]);

//   const fetchTableData = async (date: string) => {
//     setRefreshing(true);
//     const res = await get(`?date=${date}`);
//     if (res && res.success) {
//       setTableData(res.data || []);
//     } else {
//       setTableData([]);
//     }
//     setRefreshing(false);
//   };

//   const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const selected = event.target.value;
//     setSelectedDate(selected);
//   };

//   const filteredData = tableData
//     .filter(
//       (item: GetAllLecturesDaum) =>
//         moment(item.date).format("YYYY-MM-DD") === selectedDate
//     )
//     .filter((item: GetAllLecturesDaum) => item.teacher._id === teacherId); // filter by teacherId
//   // .filter((item: GetAllLecturesDaum) => item.branch._id === branchId);

//   // (item) => moment(item.date).format("YYYY-MM-DD") === selectedDate

//   const isAttendanceAllowed = (
//     fromTime: string,
//     toTime: string,
//     date: string
//   ) => {
//     const now = moment();
//     const startTime = moment(
//       ` ${date} ${fromTime}`,
//       "YYYY-MM-DD HH:mm"
//     ).subtract(10, "minutes");
//     const endTime = moment(`${date} ${toTime}, "YYYY-MM-DD HH:mm"`).add(
//       10,
//       "minutes"
//     );
//     return now.isBetween(startTime, endTime);
//   };

//   const isLectureCompleted = (toTime: string, date: string) => {
//     const now = moment();
//     const lectureDate = moment(date, "YYYY-MM-DD");
//     const endTime = moment(toTime, "HH:mm");

//     return (
//       lectureDate.isBefore(now, "day") ||
//       (lectureDate.isSame(now, "day") && now.isAfter(endTime))
//     );
//   };

//   return (
//     <div>
//       <AppProvider>
//         <h5 style={{ textAlign: "center", marginTop: "10px" }}>
//           Today's Class Report
//         </h5>

//         {/* <div className="date-picker">
//           {dates.map((date, index) => {
//             if (date === todayDate) {
//               return (
//                 <div
//                   key={index}
//                   className={`date-item ${
//                     moment(today).add(index, "days").format("YYYY-MM-DD") ===
//                     selectedDate
//                       ? "selected"
//                       : ""
//                   }`}
//                   style={{
//                     width: "50px",
//                     backgroundColor:
//                       date === todayDate ? "rgb(26 111 117)" : "transparent",
//                     color: date === todayDate ? "white" : "black",
//                     fontWeight: date === todayDate ? "bolder" : "normal",
//                   }}
//                 >
//                   {/* <div className="day">
//                     {days[(currentDayIndex + index) % 7]}
//                   </div> */}
//         {/* <div className="date">{date}</div>
//                 </div>
//               );
//             }
//             return null;
//           })}
//         </div> */}

//         <div>
//           <Container
//             style={{
//               marginTop: "1rem",
//               marginBottom: "1rem",
//             }}
//           >
//             <Card>
//               <Card.Header>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     height: "3rem",
//                     alignItems: "center",
//                   }}
//                 >
//                   <h5>Lecture ({moment(selectedDate).format("DD-MM-YYYY")})</h5>

//                   <input
//                     type="date"
//                     style={{ borderRadius: "6px", padding: "6px" }}
//                     value={selectedDate}
//                     onChange={handleDateChange}
//                   />
//                 </div>
//               </Card.Header>

//               <Card.Body>
//                 <div className="table-responsive">
//                   <Table striped bordered hover>
//                     <thead>
//                       <tr>
//                         <th>#</th>
//                         <th>Date</th>
//                         <th>Subject</th>
//                         <th>From</th>
//                         <th>To</th>
//                         <th>Teacher</th>
//                         <th>Branch</th>
//                         <th>Batch</th>
//                         <th>Standard</th>
//                         <th>Status</th>
//                         <th
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                         >
//                           Action
//                         </th>
//                       </tr>
//                     </thead>

//                     <tbody>
//                       {filteredData.length > 0 ? (
//                         filteredData
//                           .sort((a, b) => {
//                             const timeA = a.fromTime.split(":");
//                             const timeB = b.fromTime.split(":");
//                             return (
//                               parseInt(timeA[0]) * 60 +
//                               parseInt(timeA[1]) -
//                               (parseInt(timeB[0]) * 60 + parseInt(timeB[1]))
//                             );
//                           })
//                           .filter((x) => x.branch._id === branchId)
//                           .map((item, index) => (
//                             <tr key={item._id}>
//                               <td>{index + 1}</td>
//                               <td>
//                                 {moment(item.date).format("DD-MM-YYYY") || ""}
//                               </td>
//                               <td>{item.subject.name || ""}</td>
//                               <td>{item.fromTime || ""}</td>
//                               <td>{item.toTime || ""}</td>
//                               <td>{item.teacher.name || ""}</td>
//                               <td>{item.branch.name || ""}</td>
//                               <td>{item.batch.name || ""}</td>
//                               <td>{item.standard.name || ""}</td>

//                               <td>
//                                 {isLectureCompleted(item.toTime, item.date) ? (
//                                   <Badge bg="success">Completed</Badge>
//                                 ) : (
//                                   <Badge bg="danger">Pending</Badge>
//                                 )}
//                               </td>
//                               <td
//                                 style={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                   justifyContent: "center",
//                                 }}
//                               >
//                                 <div style={{ display: "flex", gap: "1rem" }}>
//                                   <Button
//                                     onClick={() =>
//                                       navigate(`/attendance/${item._id}`)
//                                     }
//                                     // disabled={
//                                     //   !isAttendanceAllowed(
//                                     //     item.fromTime,
//                                     //     item.toTime,
//                                     //     item.date
//                                     //   )
//                                     // }
//                                   >
//                                     Attendence
//                                   </Button>
//                                 </div>
//                               </td>
//                             </tr>
//                           ))
//                       ) : (
//                         <tr>
//                           <td colSpan={11} style={{ textAlign: "center" }}>
//                             No data available for the selected date.
//                           </td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </Table>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Container>
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Class;

// import React, { useEffect, useState } from "react";
// import AppProvider from "../components/AppProvider";
// import { Badge, Button, Card, Container, Table } from "react-bootstrap";
// import moment from "moment";
// import { useNavigate } from "react-router-dom";
// import { useFetch } from "use-http";
// import { GetAllLecturesDaum } from "../utils/types";
// import "../css/class.css";
// import { GetAllMyLecturesRoot } from "../utils/types";
// import { useAppSelector } from "../store/hooks";
// import ReactPaginate from "react-paginate";

// const Class = () => {
//   const navigate = useNavigate();
//   const today = new Date();
//   const todayDate = today.getDate();
//   const days = Array.from({ length: 31 }, (_, index) => {
//     const date = new Date(today.getFullYear(), today.getMonth(), index + 1);
//     return date.toLocaleString("default", { weekday: "short" });
//   });
//   const dates = Array.from({ length: 31 }, (_, i) => i + 1);

//   const [selectedDate, setSelectedDate] = useState<string>(
//     moment().format("YYYY-MM-DD")
//   );

//   const [tableData, setTableData] = useState<GetAllLecturesDaum[]>([]);
//   const { get } = useFetch<GetAllMyLecturesRoot>("/lecture");
//   const [refreshing, setRefreshing] = useState(true);

//   const teacherId = useAppSelector((state) => state.authReducer._id);
//   const branchId = useAppSelector((state) => state.branchReducer._id);

//   useEffect(() => {
//     fetchTableData(selectedDate);
//   }, [selectedDate]);

//   const fetchTableData = async (date: string) => {
//     setRefreshing(true);
//     const res = await get(`?date=${date}`);
//     if (res && res.success) {
//       setTableData(res.data || []);
//     } else {
//       setTableData([]);
//     }
//     setRefreshing(false);
//   };

//   const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     const selected = event.target.value;
//     setSelectedDate(selected);
//   };

//   const filteredData = tableData
//     .filter(
//       (item: GetAllLecturesDaum) =>
//         moment(item.date).format("YYYY-MM-DD") === selectedDate
//     )
//     .filter((item: GetAllLecturesDaum) => item.teacher._id === teacherId)
//     .filter(
//       (item: GetAllLecturesDaum) => !branchId || item.branch._id === branchId
//     );

//   const isAttendanceAllowed = (
//     fromTime: string,
//     toTime: string,
//     date: string
//   ) => {
//     const now = moment();
//     const startTime = moment(
//       ` ${date} ${fromTime}`,
//       "YYYY-MM-DD HH:mm"
//     ).subtract(10, "minutes");
//     const endTime = moment(`${date} ${toTime}, "YYYY-MM-DD HH:mm"`).add(
//       10,
//       "minutes"
//     );
//     return now.isBetween(startTime, endTime);
//   };

//   // const isLectureCompleted = (toTime: string, date: string) => {
//   //   const now = moment();
//   //   const lectureDate = moment(date, "YYYY-MM-DD");
//   //   const endTime = moment(toTime, "HH:mm");

//   //   return (
//   //     lectureDate.isBefore(now, "day") ||
//   //     (lectureDate.isSame(now, "day") && now.isAfter(endTime))
//   //   );
//   // };

//   const isLectureCompleted = (
//     fromTime: string,
//     toTime: string,
//     date: string
//   ) => {
//     const now = moment();
//     const startTime = moment(
//       `${date} ${fromTime}`,
//       "YYYY-MM-DD HH:mm"
//     ).subtract(10, "minutes");
//     const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
//       10,
//       "minutes"
//     );

//     return now.isAfter(endTime);
//   };

//   return (
//     <div>
//       <AppProvider>
//         <h5 style={{ textAlign: "center", marginTop: "10px" }}>
//           Today's Class Report
//         </h5>

//         <div>
//           <Container
//             style={{
//               marginTop: "1rem",
//               marginBottom: "1rem",
//             }}
//           >
//             <Card>
//               <Card.Header>
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     height: "3rem",
//                     alignItems: "center",
//                   }}
//                 >
//                   <h5>Lecture ({moment(selectedDate).format("DD-MM-YYYY")})</h5>

//                   <input
//                     type="date"
//                     style={{ borderRadius: "6px", padding: "6px" }}
//                     value={selectedDate}
//                     onChange={handleDateChange}
//                   />
//                 </div>
//               </Card.Header>

//               <Card.Body>
//                 <div className="table-responsive">
//                   <Table striped bordered hover>
//                     <thead>
//                       <tr>
//                         <th>#</th>
//                         <th>Date</th>
//                         <th>Subject</th>
//                         <th>From</th>
//                         <th>To</th>
//                         <th>Teacher</th>
//                         <th>Branch</th>
//                         <th>Batch</th>
//                         <th>Standard</th>
//                         <th>Lecture Status</th>
//                         <th
//                           style={{
//                             display: "flex",
//                             justifyContent: "center",
//                             alignItems: "center",
//                           }}
//                         >
//                           Action
//                         </th>
//                       </tr>
//                     </thead>

//                     <tbody>
//                       {filteredData.length > 0 ? (
//                         filteredData
//                           .sort((a, b) => {
//                             const timeA = a.fromTime.split(":");
//                             const timeB = b.fromTime.split(":");
//                             return (
//                               parseInt(timeA[0]) * 60 +
//                               parseInt(timeA[1]) -
//                               (parseInt(timeB[0]) * 60 + parseInt(timeB[1]))
//                             );
//                           })
//                           .map((item, index) => (
//                             <tr key={item._id}>
//                               <td>{index + 1}</td>
//                               <td>
//                                 {moment(item.date).format("DD-MM-YYYY") || ""}
//                               </td>
//                               <td>{item.subject.name || ""}</td>
//                               <td>{item.fromTime || ""}</td>
//                               <td>{item.toTime || ""}</td>
//                               <td>{item.teacher.name || ""}</td>
//                               <td>{item.branch.name || ""}</td>
//                               <td>{item.batch.name || ""}</td>
//                               <td>{item.standard.name || ""}</td>

//                               {/* <td>
//                                 {isLectureCompleted(item.toTime, item.date) ? (
//                                   <Badge bg="success">Completed</Badge>
//                                 ) : (
//                                   <Badge bg="danger">Pending</Badge>
//                                 )}
//                               </td> */}
//                               <td>
//                                 {isLectureCompleted(
//                                   item.fromTime,
//                                   item.toTime,
//                                   item.date
//                                 ) ? (
//                                   <Badge bg="success">Completed</Badge>
//                                 ) : (
//                                   <Badge bg="danger">Pending</Badge>
//                                 )}
//                               </td>
//                               <td
//                                 style={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                   justifyContent: "center",
//                                 }}
//                               >
//                                 <div style={{ display: "flex", gap: "1rem" }}>
//                                   <Button
//                                     onClick={() =>
//                                       navigate(`/attendance/${item._id}`)
//                                     }
//                                   >
//                                     Attendence
//                                   </Button>
//                                 </div>
//                               </td>
//                             </tr>
//                           ))
//                       ) : (
//                         <tr>
//                           <td colSpan={11} style={{ textAlign: "center" }}>
//                             No data available for the selected date.
//                           </td>
//                         </tr>
//                       )}
//                     </tbody>
//                   </Table>
//                 </div>
//               </Card.Body>
//             </Card>
//           </Container>
//         </div>
//       </AppProvider>
//     </div>
//   );
// };

// export default Class;

import React, { useEffect, useState } from "react";
import AppProvider from "../components/AppProvider";
import { Badge, Button, Card, Container, Form, Table } from "react-bootstrap";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useFetch } from "use-http";
import { GetAllLecturesDaum } from "../utils/types";
import "../css/class.css";
import { GetAllMyLecturesRoot } from "../utils/types";
import { useAppSelector } from "../store/hooks";

const Class = () => {
  const navigate = useNavigate();
  const today = new Date();
  const todayDate = today.getDate();
  const days = Array.from({ length: 31 }, (_, index) => {
    const date = new Date(today.getFullYear(), today.getMonth(), index + 1);
    return date.toLocaleString("default", { weekday: "short" });
  });
  const dates = Array.from({ length: 31 }, (_, i) => i + 1);

  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format("YYYY-MM-DD")
  );

  const [tableData, setTableData] = useState<GetAllLecturesDaum[]>([]);
  const { get } = useFetch<GetAllMyLecturesRoot>("/lecture");
  const [refreshing, setRefreshing] = useState(true);

  const teacherId = useAppSelector((state) => state.authReducer._id);
  const branchId = useAppSelector((state) => state.branchReducer._id);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredLectures, setFilteredLectures] = useState<any[]>([]);

  useEffect(() => {
    fetchTableData(selectedDate);
  }, [selectedDate]);

  const fetchTableData = async (date: string) => {
    setRefreshing(true);
    const res = await get(`?date=${date}`);
    if (res && res.success) {
      setTableData(res.data || []);
    } else {
      setTableData([]);
    }
    setRefreshing(false);
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selected = event.target.value;
    setSelectedDate(selected);
  };

  const filteredData = tableData
    .filter(
      (item: GetAllLecturesDaum) =>
        moment(item.date).format("YYYY-MM-DD") === selectedDate
    )
    .filter((item: GetAllLecturesDaum) => item.teacher._id === teacherId)
    .filter(
      (item: GetAllLecturesDaum) => !branchId || item.branch._id === branchId
    );

  const isAttendanceAllowed = (
    fromTime: string,
    toTime: string,
    date: string
  ) => {
    const now = moment();
    const startTime = moment(
      `${date} ${fromTime}`,
      "YYYY-MM-DD HH:mm"
    ).subtract(10, "minutes");
    const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
      10,
      "minutes"
    );
    return now.isBetween(startTime, endTime);
  };

  const isLectureCompleted = (
    fromTime: string,
    toTime: string,
    date: string
  ) => {
    const now = moment();
    const endTime = moment(`${date} ${toTime}`, "YYYY-MM-DD HH:mm").add(
      10,
      "minutes"
    );
    return now.isAfter(endTime);
  };
  // const handleSearch = () => {
  //   if (Response) {
  //     const lowercasedSearchTerm = searchTerm.toLowerCase();
  //     const filteredData = Response.filter((lecture: any) =>
  //       lecture.date.toLowerCase().includes(lowercasedSearchTerm)
  //     );
  //     setFilteredLectures(filteredData);
  //   }
  // };

  return (
    <div>
      <AppProvider>
        <h5 style={{ textAlign: "center", marginTop: "10px" }}>
          Today's Class Report
        </h5>

        <div>
          <Container
            style={{
              marginTop: "1rem",
              marginBottom: "1rem",
            }}
          >
            <Card>
              <Card.Header>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    height: "3rem",
                    alignItems: "center",
                  }}
                >
                  <h5>Lecture ({moment(selectedDate).format("DD-MM-YYYY")})</h5>
                  {/* <Form.Control
                    type="text"
                    placeholder="Search by date (DD-MM-YYYY)"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Button variant="primary" onClick={handleSearch}>
                    Search
                  </Button> */}

                  <input
                    type="date"
                    style={{ borderRadius: "6px", padding: "6px" }}
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                </div>
              </Card.Header>

              <Card.Body>
                <div className="table-responsive">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Subject</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Teacher</th>
                        <th>Branch</th>
                        <th>Batch</th>
                        <th>Standard</th>
                        <th>Lecture Status</th>
                        <th
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {filteredData.length > 0 ? (
                        filteredData
                          .sort((a, b) => {
                            const timeA = a.fromTime.split(":");
                            const timeB = b.fromTime.split(":");
                            return (
                              parseInt(timeA[0]) * 60 +
                              parseInt(timeA[1]) -
                              (parseInt(timeB[0]) * 60 + parseInt(timeB[1]))
                            );
                          })
                          .map((item, index) => {
                            const isPending = !isLectureCompleted(
                              item.fromTime,
                              item.toTime,
                              item.date
                            );
                            const attendanceAllowed = isAttendanceAllowed(
                              item.fromTime,
                              item.toTime,
                              item.date
                            );

                            return (
                              <tr key={item._id}>
                                <td>{index + 1}</td>
                                <td>
                                  {moment(item.date).format("DD-MM-YYYY") || ""}
                                </td>
                                <td>{item.subject.name || ""}</td>
                                <td>{item.fromTime || ""}</td>
                                <td>{item.toTime || ""}</td>
                                <td>{item.teacher.name || ""}</td>
                                <td>{item.branch.name || ""}</td>
                                <td>{item.batch.name || ""}</td>
                                <td>{item.standard.name || ""}</td>

                                <td>
                                  {isPending ? (
                                    <Badge bg="danger">Pending</Badge>
                                  ) : (
                                    <Badge bg="success">Completed</Badge>
                                  )}
                                </td>
                                <td
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div style={{ display: "flex", gap: "1rem" }}>
                                    <Button
                                      variant={
                                        attendanceAllowed ? "danger" : "primary"
                                      }
                                      onClick={() =>
                                        navigate(`/attendance/${item._id}`)
                                      }
                                    >
                                      Attendance
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={11} style={{ textAlign: "center" }}>
                            No data available for the selected date.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Container>
        </div>
      </AppProvider>
    </div>
  );
};

export default Class;
