const TermsAndConditions = () => {
  return (
    <div
      style={{
        margin: 10,
        padding: 10,
      }}
    >
      <h1>Terms & Conditions</h1>
      <p>
        <strong>Effective Date:</strong> 01-01-2025 (DD-MM-YYYY)
      </p>

      <h2>1. Introduction</h2>
      <p>
        Welcome to <strong>SCPL TEAM</strong>, designed for teachers in
        educational organizations. By accessing or using the App, you agree to
        comply with these Terms & Conditions.
      </p>

      <h2>2. Use of the App</h2>
      <p>
        The App is intended for teachers to view lectures, tests, appointments
        with parents, and other related tasks. Access to the App is granted only
        to users who are part of an authorized organization.
      </p>

      <h2>3. Login Credentials</h2>
      <p>
        The App does not require account creation by the user. Your login
        credentials will be provided by your organization. You must log in to
        access the App’s features.
      </p>

      <h2>4. Access Restrictions</h2>
      <p>
        You are required to log in to the App. Without valid login credentials,
        access to the App is not permitted.
      </p>

      <h2>5. Violations</h2>
      <p>
        If you violate any of these Terms & Conditions, appropriate action will
        be taken by your organization. This may include suspension or
        termination of access to the App.
      </p>

      <h2>6. Organization Requirement</h2>
      <p>
        To use the App, you must be an active teacher associated with the
        organization. The App is not available for unauthorized users.
      </p>

      <h2>7. Limitations of Liability</h2>
      <p>
        The App is provided "as is" and we do not guarantee uninterrupted or
        error-free operation. The organization is responsible for taking action
        in the case of misuse.
      </p>

      <h2>8. Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms & Conditions at any time. Any
        changes will be effective upon being published on our platform.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions or concerns, you can contact us at
        nextgenideasolutions1@gmail.com or visit our website{" "}
        <a href="https://scpledu.com">https://scpledu.com.</a>
      </p>
    </div>
  );
};

export default TermsAndConditions;
